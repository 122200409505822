import ReactDOM from 'react-dom/client'
import { App } from './App'

const container = document.getElementById('root')

const root = ReactDOM.createRoot(container)

// set token in local storage
// TODO: check that token has actually been set

root.render(<App />)
